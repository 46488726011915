<template>
  <div class="row">
    <div class="col-md-12">
      <section class="breadcrumb-section set-bg" style="height:200px; background-image: url('img/breadcrumb.jpg'); background-size: auto;"  data-setbg="img/breadcrumb.jpg">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <div class="breadcrumb__text">
                <h2>{{ title }}</h2>
                <div class="breadcrumb__option">
                  <a href="./index.html">Acceuil</a>
                  <span>{{ title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props:["title"]
}
</script>

<style scoped>

</style>