<template>
  <div>
    <div style="" class="container-scroller d-flex">

      <div style="width:100%;" class="container-fluid page-body-wrapper">
        <!-- partial:./partials/_navbar.html -->
        <div class="row">
          <div class="col-md-4" style="background-color: #00aeef; padding: 3px;">

          </div>
          <div class="col-md-4"  style="background-color: #fff200; padding: 3px;">

          </div>
          <div class="col-md-4"  style="background-color: #ed1c24; padding: 3px;">

          </div>
        </div>

        <nav class="navbar col-lg-12 col-12 px-0 py-0 py-lg-4 d-flex flex-row">
          <div class="navbar-menu-wrapper">
            <h5 style=""><span class="mdi mdi-arob"></span>SYSTEME NATIONAL D'INFORMATION AGRICOLE ET NUTRITIONEL</h5>
            <p>@copyright Ministère de l'agriculture - 2024</p>
          </div>
          <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <h4 class="font-weight-bold mb-0 d-none d-md-block mt-1"><span class="mdi mdi-bullseye"></span>&nbsp;Tout sur l'agriculture de la RDC...</h4>
            <ul style="visibility: hidden" class="navbar-nav navbar-nav-right">
              <li style="display:none; visibility: hidden;" class="nav-item dropdown me-1">
                <a class="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center" id="messageDropdown" href="#" data-bs-toggle="dropdown">
                  <i class="mdi mdi-calendar mx-0"></i>
                  <span class="count bg-info">2</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                  <p class="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                      <img src="images/faces/face4.jpg" alt="image" class="profile-pic">
                    </div>
                    <div class="preview-item-content flex-grow">
                      <h6 class="preview-subject ellipsis font-weight-normal">David Grey
                      </h6>
                      <p class="font-weight-light small-text text-muted mb-0">
                        The meeting is cancelled
                      </p>
                    </div>
                  </a>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                      <img src="images/faces/face2.jpg" alt="image" class="profile-pic">
                    </div>
                    <div class="preview-item-content flex-grow">
                      <h6 class="preview-subject ellipsis font-weight-normal">Tim Cook
                      </h6>
                      <p class="font-weight-light small-text text-muted mb-0">
                        New product launch
                      </p>
                    </div>
                  </a>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                      <img src="images/faces/face3.jpg" alt="image" class="profile-pic">
                    </div>
                    <div class="preview-item-content flex-grow">
                      <h6 class="preview-subject ellipsis font-weight-normal"> Johnson
                      </h6>
                      <p class="font-weight-light small-text text-muted mb-0">
                        Upcoming board meeting
                      </p>
                    </div>
                  </a>
                </div>
              </li>
              <li style="display: none; visibility: hidden;" class="nav-item dropdown me-2">
                <a class="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                  <i class="mdi mdi-email-open mx-0"></i>
                  <span class="count bg-danger">1</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                  <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-success">
                        <i class="mdi mdi-information mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal">Application Error</h6>
                      <p class="font-weight-light small-text mb-0 text-muted">
                        Just now
                      </p>
                    </div>
                  </a>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-warning">
                        <i class="mdi mdi-settings mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal">Settings</h6>
                      <p class="font-weight-light small-text mb-0 text-muted">
                        Private message
                      </p>
                    </div>
                  </a>
                  <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-info">
                        <i class="mdi mdi-account-box mx-0"></i>
                      </div>
                    </div>
                    <div class="preview-item-content">
                      <h6 class="preview-subject font-weight-normal">New user registration</h6>
                      <p class="font-weight-light small-text mb-0 text-muted">
                        2 days ago
                      </p>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
              <span class="mdi mdi-menu"></span>
            </button>
          </div>
          <div class="navbar-menu-wrapper navbar-search-wrapper d-none d-lg-flex align-items-center">
            <ul class="navbar-nav mr-lg-2">
              <li class="nav-item nav-search d-none d-lg-block">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Tapez ici..." aria-label="recherche" aria-describedby="recherche">
                </div>
              </li>
            </ul>
            <ul class="navbar-nav navbar-nav-right">
              <li class="nav-item">
                <a href="#" @click.prevent="search" class="btn btn-default" style="border:1px solid gray">
                  Recherche
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <!-- partial -->
        <div class="main-panel">
          <div class="content-wrapper">

            <!-- indactors section !-->
            <div class="row">
              <div class="col-md-4 stretch-card">
                <div class="card" style=" height:130px;">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <p class="card-title"><span class="mdi mdi-chart-areaspline"></span> Production local</p>
                      <p class="text-success font-weight-medium">+7%</p>
                    </div>
                    <div class="d-flex align-items-center flex-wrap mb-3">
                      <h5 class="font-weight-normal mb-0 mb-md-1 mb-lg-0 me-3" style="background-color:green; color:white; padding: 5px; border-radius:5px;">{{localProduction}}<sup>tonne</sup></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card">
                <div class="card" style=" height:130px;">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <p class="card-title"><span class="mdi mdi-chart-areaspline"></span>  Importation</p>
                      <p class="text-success font-weight-medium">+15%</p>
                    </div>
                    <div class="d-flex align-items-center flex-wrap mb-3">
                      <h5 class="font-weight-normal mb-0 mb-md-1 mb-lg-0 me-3" style="background-color:green; color:white; padding: 5px; border-radius:5px;">{{importation}}<sup>tonne</sup></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 stretch-card">
                <div class="card" style=" height:130px;">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <p class="card-title"><span class="mdi mdi-chart-areaspline"></span>  Autosuffisance Alimentaire</p>
                      <p class="text-success font-weight-medium">+{{autosuffisanceGrowth}}%</p>
                    </div>
                    <div class="d-flex align-items-center flex-wrap mb-3">
                      <h5 class="font-weight-normal mb-0 mb-md-1 mb-lg-0 me-3" style="background-color:green; color:white; padding: 5px; border-radius:5px;"><span class="mdi mdi-chart-areaspline"></span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin: 10px;">
              <div class="col-md-12" style="justify-content: center">
                <h4 style="text-align: center; ">Tirer une statistique</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div style="width: 100%; justify-content: center; display: flex;">
                  <button @click.prevent="toggleConsultStat('comparaison')" style="border:1px solid black; padding: 5px; margin:2px;"><span class="mdi mdi-chart-bar"></span> Stat comparative</button>
                  <button @click.prevent="toggleConsultStat('trend')" style="border:1px solid black; padding: 5px; margin:2px;"><span class="mdi mdi-chart-line"></span> Tendance culture</button>
                </div>
              </div>
            </div>

            <loader :data-loaded="consultStat" height="400">
              <!-- content !-->
              <div>
                <div style="margin-top: 20px">
                  <BarChart @generatestat="generateStat" v-if="statType==='comparaison'"></BarChart>
                  <TrendChart @generatestat="generateStat" v-else-if="statType==='trend'"></TrendChart>
                </div>
              </div>
            </loader>
          </div>
          <!-- content-wrapper ends -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </div>
</template>

<script>
import BarChart from "@/sections/BarChart";
import TrendChart from "@/sections/TrendChart";
import loader from "@/widgets/loader";
import {useToast} from 'vue-toast-notification'
export default {
  name: "Home",
  data:function(){
    return {
      consultStat:false,
      localProduction:4.5,
      importation:9,
      autosuffisanceGrowth:3,
      statType:"comparaison"
    }
  },
  components:{BarChart,TrendChart,loader},
  computed:{

  },
  methods:{
    toggleConsultStat(statType)
    {
      this.statType=statType;
      if(!this.consultStat)
      {
        this.consultStat=true;
      }

    },
    generateStat()
    {

      this.consultStat=false
      setTimeout(()=>{
        this.consultStat=true
    },2000);
    },
    search()
    {
      this.$toast.open({
        message:"Cette fonctionnalité n'est pas encore disponible.",
        type:'error'
      });
    }

  },
  mounted()
  {
  }
}
</script>

<style scoped>

</style>