<template>
  <div>
    <div class="row">
      <div class="col-md-3" style="">
        <div style="" class="form-group">
          <label for="exampleFormControlSelect2"><span class="mdi mdi-check-circle"></span> Culture : {{cultureSelected}}</label>
          <select v-model="cultureSelected" class="form-control" id="exampleFormControlSelect2">
            <option  v-for="(culture,index) in cultures" :value="culture" :key="index">{{culture}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3" style="">
        <div style="" class="form-group">
          <label for="exampleFormControlSelect2"><span class="mdi mdi-map-marker"></span> Zone Géographique : {{zoneSelected}}</label>
          <select v-model="zoneSelected" class="form-control" id="exampleFormControlSelect2">
            <option  v-for="(zone,index) in zones" :value="zone" :key="index">{{zone}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3" style="">
        <div style="" class="form-group">
          <label for="exampleFormControlSelect2"><span class="mdi mdi-calendar"></span> Année : {{yearSelected}}</label>
          <select v-model="yearSelected" class="form-control" id="exampleFormControlSelect2">
            <option  v-for="(year,index) in years" :value="year" :key="index">{{year}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-2" style="display: flex; justify-content: center">
        <div class="form-group" style="margin: auto">
          <button @click.prevent="generateStat" type="button" class="btn btn-outline-primary btn-icon-text">
            <i class="mdi mdi-chart-areaspline btn-icon-prepend"></i>
            Générer
          </button>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <p><span class="mdi mdi-information"></span> Ce graphique prend en compte les données de la culture selectionnée dans la zone et année selectionnée.</p>
      </div>
    </div>
    <div class="row" style="background-color: white; ">
      <div class="col-md-12">
        <div style=" width:100%;">
          <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TrendChart",
  data:function(){
    {
      return {
        series: [{
          name: '',
          data: [30, 45, 70, 65, 80,82, 90, 55, 50, 49,70,80]
        }],
        chartOptions: {
          chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true
            },
            toolbar: {
              autoSelected: 'zoom'
            }
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Tendance',
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
            labels: {
              formatter: (val) =>{
                return val
              }
            },
            title: {
              text: 'tonne(en millier)'
            },
          },
          xaxis: {
//            type: 'datetime',
            categories: ['Janv', 'Fev','Mar','Avr','Mai','Jui','Aou','Sept','Oct','Nov','Dec']
          },
          tooltip: {
            shared: false,
            y: {
              formatter: (val) => (val / 1000000).toFixed(0)
            }
          }
        },
        zones:["Etendue national","Haut-Katanga","Kasai"],
        years:[2023,2022,2021],
        cultures:["Riz","Farine de Manioc","Farine de Mais","Aubergines","Gombo","Pomme de terre"],
        zoneSelected:"Etendue national",
        yearSelected:2023,
        cultureSelected:"Riz"
      }
    }

  },
  methods:{
    generateStat()
    {
      this.$emit("generatestat");
    }
  }
}
</script>

<style scoped>

</style>