<template>
  <div>
    <Header></Header>

    <Breadcrumb title="Connexion"/>

    <div class="container">
      <div class="row" style="justify-content: center; ">

        <div class="col-md-4" style="margin: 5px; border:1px solid lightgray; border-radius:5px; padding-top: 20px; margin-top: 20px;">
          <p style="color:black; font-family:Arial; font-style: italic; font-size: 13px;"><span class="fa fa-user"></span> Créer un nouveau compte ECONOMAT</p>
          <p v-if="errorMsg.length>0" style="color: red; font-size: 13px;"><span class="fa fa-warning"></span> {{errorMsg}}</p>
          <InputWidget @onerror="displayError" name="fullname" @onvalue="setUserValue" label="Nom complet" :required="true"></InputWidget>
          <InputWidget @onerror="displayError" name="matricule" @onvalue="setUserValue" label="Matricule" :required="true"></InputWidget>
          <InputWidget @onerror="displayError" name="telephone" @onvalue="setUserValue" label="Téléphone" :required="true"></InputWidget>
          <InputWidget @onerror="displayError" name="pass1" @onvalue="setUserValue" label="Mot de passe" :obscureText="true" :required="true"></InputWidget>
          <InputWidget @onerror="displayError" name="pass2" @onvalue="setUserValue" label="Retapez mot de passe" :obscureText="true" :required="true"></InputWidget>
          <div class="form-group">
            <button @click.prevent="registerUser" class="btn btn-success"><span class="fa fa-lock"></span> Créer compte</button>
            <p style="font-size: 13px; color:black;">Ou</p>
            <p><a href="#/login" class="btn btn-default" style="border:1px solid lightblue;">Se connecter à mon compte</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data(){
    return {
      user:{
        fullname:"",
        matricule:"",
        telephone:"",
        pass:""
      },
      errorMsg:""
    }
  },
  methods:{
    async registerUser()
    {
      if(this.errorMsg.length>0)
      {
        //return;
      }
      await this.$store.dispatch("registerUser",this.user);
      this.$router.replace("/");
    },
    setUserValue(value,name)
    {
      console.log(value+"#"+name);
      if(name==="fullname")
      {
        this.user.fullname=value;
      }
      else if(name==="matricule")
      {
        this.user.matricule=value;
      }
      else if(name==="telephone")
      {
        this.user.telephone=value;
      }
      else if(name==="pass1")
      {
        this.user.pass=value;
      }
      else if(name==="pass2")
      {
        if(this.user.pass!==value)
        {
          this.displayError("Vos mots de passe ne correspondent pas!");
          return;
        }
      }

    },
    displayError(msg)
    {
      this.errorMsg=msg;
    }
  }
}
</script>

<style scoped>

</style>