import cacheStore from "../cacheStore";
const getters = {
    getTotalCart(state)
    {
        var total=0;
        var cache=cacheStore.get("cart");
        if(cache!==null)
        {
            state.panier=cache;
        }
        for(var i=0; i<state.panier.length; i++)
        {
            total+=state.panier[i].prix;
        }

        return total;
    },
    getUser(state)
    {
        var cache=cacheStore.get("user");
        if(cache!==null)
        {
            state.user=cache;
        }
        return state.user;
    },
    getUsers(state)
    {
        var cache=cacheStore.get("users");
        if(cache!==null)
        {
            state.users=cache;
        }

        return state.users;
    },
    getPanier(state)
    {
        var cache=cacheStore.get("cart");
        if(cache!==null)
        {
            state.panier=cache;
        }

        return state.panier;
    }
};

export default getters;