<template>
  <div class="form-group">
    <label for=""><span v-if="required">*</span> {{ label }}</label>
    <input v-model="value" v-if="!obscureText" type="text" class="form-control" :placeholder="placeholder" @focusout="emitValue">
    <input v-model="value" v-else type="password" class="form-control" :placeholder="placeholder" @focusout="emitValue">
  </div>
</template>

<script>
export default {
  name: "InputWidget",
  data(){
    return {
      value:"",
      errorMsg:""
    }
  },
  props:{
    label:String,
    name:String,
    placeholder:String,
    required:{
      type:Boolean,
      default:false
    },
    obscureText:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    emitValue()
    {
      if(this.required && this.value.length<1)
      {
        this.emitError(this.label+" : obligatoire.");
      }
      else
      {
        this.$emit("onvalue",this.value,this.name)
      }
      //console.log(this.value);
    },
    emitError(msg){
      this.$emit("onerror",msg);
    }
  }
}
</script>

<style scoped>

</style>