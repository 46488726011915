<template>
  <div>
    <div class="row">
      <div class="col-md-4" style="">
        <div style="" class="form-group">
          <label for="exampleFormControlSelect2"><span class="mdi mdi-map-marker"></span> Zone Géographique : {{zoneSelected}}</label>
          <select v-model="zoneSelected" class="form-control" id="exampleFormControlSelect2">
            <option  v-for="(zone,index) in zones" :value="zone" :key="index">{{zone}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4" style="">
        <div style="" class="form-group">
          <label for="exampleFormControlSelect2"><span class="mdi mdi-calendar"></span> Année : {{yearSelected}}</label>
          <select v-model="yearSelected" class="form-control" id="exampleFormControlSelect2">
            <option  v-for="(year,index) in years" :value="year" :key="index">{{year}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-2" style="display: flex; justify-content: center">
        <div class="form-group" style="margin: auto">
          <button @click.prevent="generateStat" type="button" class="btn btn-outline-primary btn-icon-text">
            <i class="mdi mdi-chart-areaspline btn-icon-prepend"></i>
            Générer
          </button>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <p><span class="mdi mdi-information"></span> Cette étude prend en compte les données de tout les produits de l'année selectionnée de la zone  selectionnée.</p>
      </div>
    </div>
    <div class="row" style="background-color: white; ">
      <div class="col-md-12">
        <div style=" width:100%;">
          <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarChart",
  data:function(){
    return {
      consultStat:false,
      series: [
        {
          name: 'Semence',
          data: [44, 55, 57, 56, 61]
        }, {
          name: 'Production',
          data: [76, 85, 101, 37, 30]
        }, {
          name: 'Attaques',
          data: [35, 41, 36, 70, 90]
        }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Janvier', 'Mars', 'Juin', 'Septembre', 'Decembre'],
        },
        yaxis: {
          title: {
            text: 'tonne'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "" + val + " tonne ou hectare"
            }
          }
        }
      },
      years:[2023,2022,2021],
      localProduction:4.5,
      importation:9,
      autosuffisanceGrowth:3,
      zones:["Etendue national","Haut-Katanga","Kasai"],
      zoneSelected:"Etendue national",
      yearSelected:2023
    }
  },
  methods:{
    generateStat()
    {
      this.$emit("generatestat");
      console.log("emit");
    }
  }
}
</script>

<style scoped>

</style>