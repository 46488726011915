import Home from "@/pages/Home";

import Cart from "@/pages/Cart";

import cacheStore from "../store/cacheStore";

import Login from "@/pages/Login";

import Register from "@/pages/Register";

function auth(to, from, next) {
  var client = cacheStore.get("client");
  //console.log(JSON.stringify(client));
  if (
    client !== null &&
    client !== undefined &&
    Object.keys(client).length > 0
  ) {
    next();
  } else {
    next({ name: "login" });
  }
}

const routes = [
  {
    path: "/",
    component: Home,
    name: "home",
  },
  {
    path:"/cart",
    component: Cart
  },
  {
    path:"/login",
    component: Login
  },
  {
    path:"/register",
    component: Register
  }
];
export default routes;
