<template>
  <div>
    <Header/>

    <Breadcrumb title="Connexion"/>

    <div class="container">
      <div class="row" style="justify-content: center; ">

        <div class="col-md-4" style=" margin: 5px; border:1px solid lightgray; border-radius:5px; padding-top: 20px; margin-top: 20px;">
         <p style="color:black; font-family:Arial; font-style: italic; font-size: 13px;"><span class="fa fa-user"></span> Connectez-vous à votre compte ECONOMAT</p>
          <p v-if="error.length>0" style="color: red; font-size: 13px;"><span class="fa fa-warning"></span> {{error}}</p>
          <InputWidget @onvalue="setValue" name="identifiant" label="Identifiant" :required="true" placeholder="email ou téléphone.."></InputWidget>
          <InputWidget @onvalue="setValue" name="pass" label="Mot de passe" :obscureText="true" :required="true" placeholder=""></InputWidget>
          <div class="form-group">
            <button @click.prevent="login" class="btn btn-success"><span class="fa fa-lock"></span> Se connecter</button>
            <p style="font-size: 13px; color:black;">Ou</p>
            <p><a href="#/register" class="btn btn-default" style="border:1px solid lightblue;">créer un nouveau compte</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data(){
    return {
      user:{
        identifiant:"",
        pass:""
      },
      error:""
    };
  },
  methods:{
    setValue(value,name)
    {
      if(name==="identifiant")
      {
        this.user.identifiant=value;
      }
      else if(name==="pass")
      {
        this.user.pass=value;
      }
    },
    displayError(msg)
    {
      this.error=msg;
    }
    ,
    async login()
    {
      /**
       * Get all users.
       */
      var users=this.$store.getters.getUsers;

      var found=false;
      for(var i=0; i<users.length; i++)
      {
        if(users[i].telephone===this.user.identifiant && users[i].pass===this.user.pass)
        {
          found=true;
          await this.$store.dispatch("login",users[i]);

          break;
        }
      }

      if(!found)
      {
        this.displayError("Téléphone ou mot de passe incorrect.");
      }
      else
      {
        this.$router.replace("/");
      }
    }
  }
}
</script>

<style scoped>

</style>