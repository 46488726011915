var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"height":"200px"}},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"header__top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"header__top__right"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"header__top__right__auth"},[(_vm.user.fullname===undefined)?_c('a',{attrs:{"href":"#/login"}},[_c('i',{staticClass:"fa fa-user"}),_vm._v(" Connexion")]):_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-user"}),_vm._v(_vm._s(_vm.user.fullname)+" ["),_c('span',{staticClass:"fa fa-close",on:{"click":_vm.logout}}),_vm._v("]")])])])])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"header__cart"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#/cart"}},[_c('i',{staticClass:"fa fa-shopping-bag"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.panier.length))])])])]),_c('a',{attrs:{"href":"#/cart"}},[_c('div',{staticClass:"header__cart__price"},[_c('span',[_vm._v("$"+_vm._s(_vm.totalPanier))])])]),_c('div',{staticClass:"header__top__right__auth",staticStyle:{"margin-left":"5px","border":"1px solid black","padding":"3px","border-radius":"5px"}},[(_vm.user.fullname===undefined)?_c('a',{attrs:{"href":"#/login"}},[_c('i',{staticClass:"fa fa-user"}),_vm._v(" Connexion")]):_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-user"}),_vm._v(_vm._s(_vm.user.fullname)+" ["),_c('span',{staticClass:"fa fa-close",on:{"click":_vm.logout}}),_vm._v("]")])])])])]),_vm._m(5)])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"header__top__left"},[_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-envelope"}),_vm._v("contact@economat.com")]),_c('li',[_c('span',{staticClass:"fa fa-phone-square"}),_vm._v(" +243810000000")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__top__right__social"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-facebook"})]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-whatsapp"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__top__right__language",staticStyle:{"display":"none"}},[_c('img',{attrs:{"src":"img/language.png","alt":""}}),_c('div',[_vm._v("English")]),_c('span',{staticClass:"arrow_carrot-down"}),_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Spanis")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("English")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"header__logo"},[_c('a',{attrs:{"href":"./index.html"}},[_c('img',{staticStyle:{"background-color":"lightgray","width":"150px","height":"50px"},attrs:{"src":"img/logoo.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6"},[_c('nav',{staticClass:"header__menu"},[_c('ul',[_c('li',{staticClass:"active"},[_c('a',{attrs:{"href":"./index.html"}},[_c('span',{staticClass:"fa fa-home"}),_vm._v("Acceuil")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"fa fa-shopping-bag"}),_vm._v(" Achat")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"fa fa-phone"}),_vm._v(" Contact")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"humberger__open"},[_c('i',{staticClass:"fa fa-bars"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"hero__categories"},[_c('div',{staticClass:"hero__categories__all"},[_c('i',{staticClass:"fa fa-bars"}),_c('span',[_vm._v("Catégories")])])])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"hero__search"},[_c('div',{staticClass:"hero__search__form"},[_c('form',{attrs:{"action":"#"}},[_c('div',{staticClass:"hero__search__categories"},[_vm._v(" Tous "),_c('span',{staticClass:"arrow_carrot-down"})]),_c('input',{attrs:{"type":"text","placeholder":"Que cherchez-vous?"}}),_c('button',{staticClass:"site-btn",attrs:{"type":"submit"}},[_vm._v("RECHERCHE")])])]),_c('div',{staticClass:"hero__search__phone"},[_c('div',{staticClass:"hero__search__phone__icon"},[_c('i',{staticClass:"fa fa-phone"})]),_c('div',{staticClass:"hero__search__phone__text"},[_c('h5',[_vm._v("+243810000000")]),_c('span',[_vm._v("support 24/7")])])])])])])])])
}]

export { render, staticRenderFns }