import cacheStore from "../cacheStore";
const mutations = {
    addToCart(state,produit){
        var cache=cacheStore.get("cart");
        if(cache!==null)
        {
            state.panier=cache;
        }
        state.panier.push(produit);
        cacheStore.save("cart",state.panier);
    },
    removeFromCart(state,produit)
    {
        var data=[];
        for(var i=0; i<state.panier.length; i++)
        {
            if(state.panier[i].title===produit.title)
            {
                continue;
            }
            data.push(state.panier[i]);
        }
        state.panier=data;
        cacheStore.save("cart",state.panier);
    },
    setUsers(state,user)
    {
        var cache=cacheStore.get("users");
        if(cache!==null)
        {
            state.users=cache;
        }
        state.users.push(user);
        cacheStore.save("users",state.users);
    },
    setUser(state,user)
    {
        cacheStore.save("user",user);
        state.user=user;
    },
    clearPanier(state)
    {
        state.panier=[];
        cacheStore.delete("cart");
    }
};

export default mutations;
