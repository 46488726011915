<template>
  <div>

    <div style=" height:200px;">
      <!-- Header Section Begin -->
      <header class="header">
        <div class="header__top">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="header__top__left">
                  <ul>
                    <li><i class="fa fa-envelope"></i>contact@economat.com</li>
                    <li><span class="fa fa-phone-square"></span> +243810000000</li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="header__top__right">
                  <div class="header__top__right__social">
                    <a href="#"><i class="fa fa-facebook"></i></a>
                    <a href="#"><i class="fa fa-whatsapp"></i></a>
                  </div>
                  <div style="display: none;" class="header__top__right__language">
                    <img src="img/language.png" alt="">
                    <div>English</div>
                    <span class="arrow_carrot-down"></span>
                    <ul>
                      <li><a href="#">Spanis</a></li>
                      <li><a href="#">English</a></li>
                    </ul>
                  </div>
                  <div class="header__top__right__auth">
                    <a v-if="user.fullname===undefined" href="#/login"><i class="fa fa-user"></i> Connexion</a>
                    <a v-else href="#"><i class="fa fa-user"></i>{{ user.fullname }} [<span @click="logout" class="fa fa-close"></span>]</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="header__logo">
                <a href="./index.html"><img style="background-color:lightgray; width:150px; height:50px;" src="img/logoo.png" alt=""></a>
              </div>
            </div>
            <div class="col-lg-6">
              <nav class="header__menu">
                <ul>
                  <li class="active"><a href="./index.html"><span class="fa fa-home"></span>Acceuil</a></li>
                  <li><a href="#"><span class="fa fa-shopping-bag"></span> Achat</a></li>
                  <li><a href="#"><span class="fa fa-phone"></span> Contact</a></li>
                </ul>
              </nav>
            </div>
            <div class="col-lg-3">
              <div class="header__cart">
                <ul>
                  <li><a href="#/cart"><i class="fa fa-shopping-bag"></i> <span>{{panier.length}}</span></a></li>
                </ul>
                <a href="#/cart"><div class="header__cart__price"><span>${{totalPanier}}</span></div></a>
                <div style="margin-left: 5px; border:1px solid black; padding:3px; border-radius:5px;" class="header__top__right__auth">
                  <a v-if="user.fullname===undefined" href="#/login"><i class="fa fa-user"></i> Connexion</a>
                  <a v-else href="#"><i class="fa fa-user"></i>{{ user.fullname }} [<span @click="logout" class="fa fa-close"></span>]</a>
                </div>
              </div>
            </div>
          </div>
          <div class="humberger__open">
            <i class="fa fa-bars"></i>
          </div>
        </div>
      </header>
      <!-- Header Section End -->

      <!-- Hero Section Begin -->
      <section class="hero">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="hero__categories">
                <div class="hero__categories__all">
                  <i class="fa fa-bars"></i>
                  <span>Catégories</span>
                </div>

              </div>
            </div>
            <div class="col-lg-9">
              <div class="hero__search">
                <div class="hero__search__form">
                  <form action="#">
                    <div class="hero__search__categories">
                      Tous
                      <span class="arrow_carrot-down"></span>
                    </div>
                    <input type="text" placeholder="Que cherchez-vous?">
                    <button type="submit" class="site-btn">RECHERCHE</button>
                  </form>
                </div>
                <div class="hero__search__phone">
                  <div class="hero__search__phone__icon">
                    <i class="fa fa-phone"></i>
                  </div>
                  <div class="hero__search__phone__text">
                    <h5>+243810000000</h5>
                    <span>support 24/7</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Hero Section End -->
    </div>
  </div>
</template>

<script>
var domain=window.location.host;

if(window.location.protocol==="http:")
{
  if(domain.includes("localhost") || domain.includes("127.0.0.1"))
  {
    domain="127.0.0.1/economat-storage";
    //domain+="/economat-new";
  }
  else
  {
    window.location.protocol="https://";
  }
}
var protocol=window.location.protocol+"//";
domain=protocol+domain;
export default {
  name: "Header",
  data(){
    return {

    }
  },
  computed:{
    user(){
      return this.$store.getters.getUser;
    },
    categories() {
      return this.$store.state.categories;
    },
    panier(){
      return this.$store.state.panier;
    },
    totalPanier(){
      return this.$store.getters.getTotalCart;
    }
  },
  methods:{
    async logout()
    {
      await this.$store.dispatch("logout");
      this.$router.go("/");
    }
  }
}
</script>

<style scoped>

</style>