
var domain=window.location.host;

if(window.location.protocol==="http:")
{
  if(domain.includes("localhost") || domain.includes("127.0.0.1"))
  {
    //domain="127.0.0.1/economat-storage";
    //domain+="/economat-new";
  }
  else
  {
    window.location.protocol="https://";
  }
}
var protocol=window.location.protocol+"//";
domain=protocol+domain;
const states = {
  baseURL: domain,
  categories:[
    {
      title:"VIVRIERS FRAIS",
      img:"",
      sousCategories:[
        "Surgelés",
        "non-surgelés",
      ],
      produits:[
        {
          title:"Poulet Pluvera(10kg)",
          prix:31.20,
          devise:"$",
          img:domain+"/products/poulet-pluvera.jpg"
          ,qte:1
        },
        {
          title:"Poulet Pluvera(11kg)",
          prix:37.18,
          devise:"$",
          img:domain+"/products/poulet-pluvera.jpg"
          ,qte:1
        },
        {
          title:"Poulet Pluvera(12kg)",
          prix:32.50,
          devise:"$",
          img:domain+"/products/poulet-pluvera.jpg"
          ,qte:1
        },
        {
          title:"Poulet Wilki(12kg)",
          prix:42.25,
          devise:"$",
          img:domain+"/products/poulet-pluvera.jpg"
          ,qte:1
        },
        {
          title:"Poulet Wilki(13kg)",
          prix:60.13,
          devise:"$",
          img:domain+"/products/poulet-pluvera.jpg"
          ,qte:1
        },
        {
          title:"Poulet Yaya(13kg)",
          prix:60.12,
          devise:"$",
          img:domain+"/products/poulet-yaya.jpg"
          ,qte:1
        },
        {
          title:"Poulet Yaya(15kg)",
          prix:46.8,
          devise:"$",
          img:domain+"/products/poulet-yaya.jpg"
          ,qte:1
        },
        {
          title:"Cuisse de poulet(10kg)",
          prix:26,
          devise:"$",
          img:domain+"/products/cuisse-de-poulet.jpg"
          ,qte:1
        },
        {
          title:"Cuisse de poulet(Maple lodge AB 10kg)",
          prix:32,
          devise:"$",
          img:domain+"/products/cuisse-de-poulet.jpg"
          ,qte:1
        },
        {
          title:"Dos de poulet(10kg)",
          prix:19.82,
          devise:"$",
          img:domain+"/products/dos-de-poulet.jpg"
          ,qte:1
        },
        {
          title:"Babine de boeuf(10kg)",
          prix:32.5,
          devise:"$",
          img:domain+"/products/babine-de-boeuf.jpg"
          ,qte:1
        },
        {
          title:"Babine de boeuf(15kg)",
          prix:48.75,
          devise:"$",
          img:domain+"/products/babine-de-boeuf.jpg"
          ,qte:1
        },
        {
          title:"Coeur de boeuf(10kg)",
          prix:23.4,
          devise:"$",
          img:domain+"/products/coeur-de-boeuf.jpg"
          ,qte:1
        },
        {
          title:"Foie de buffle",
          prix:22.1,
          devise:"$",
          img:domain+"/products/foie-de-boeuf.jpg"
          ,qte:1
        },
        {
          title:"Noix de joue(15kg)",
          prix:72.8,
          devise:"$",
          img:domain+"/products/noix-de-joue.jpg"
          ,qte:1
        },
        {
          title:"Poumon de boeuf",
          prix:20.8,
          devise:"$",
          img:domain+"/products/poumon-de-boeuf.jpg"
          ,qte:1
        },
        {
          title:"Tripe de boeuf(15kg)",
          prix:29.9,
          devise:"$",
          img:domain+"/products/tripe-de-boeuf.jpg"
          ,qte:1
        },
        {
          title:"Viande de boeuf(15kg)",
          prix:65,
          devise:"$",
          img:domain+"/products/viande-rumsteck.jpg"
          ,qte:1
        },
        {
          title:"Croupion de dinde(medina halal)",
          prix:35.1,
          devise:"$",
          img:domain+"/products/croupion-de-dinde.jpg"
          ,qte:1
        },
        {
          title:"Croupion de dinde(Boxparts)",
          prix:35.1,
          devise:"$",
          img:domain+"/products/croupion-de-dinde.jpg"
          ,qte:1
        },
        {
          title:"Cou de dinde",
          prix:29.9,
          devise:"$",
          img:domain+"/products/cou-de-dinde.jpg"
          ,qte:1
        },
        {
          title:"Poisson chinchard(10kg, M)",
          prix:31,
          devise:"$",
          img:domain+"/products/poisson-chinchard.jpg"
          ,qte:1
        },
        {
          title:"Poisson chinchard(15kg, M)",
          prix:36,
          devise:"$",
          img:domain+"/products/poisson-chinchard.jpg"
          ,qte:1
        },
        {
          title:"Poisson THON(10kg, L)",
          prix:26,
          devise:"$",
          img:domain+"/products/poisson-thon.jpg"
          ,qte:1
        },
        {
          title:"Poisson THON(15kg, L)",
          prix:38,
          devise:"$",
          img:domain+"/products/poisson-thon.jpg"
          ,qte:1
        },
        {
          title:"Poisson Malangwa(10kg, 300-500)",
          prix:29.9,
          devise:"$",
          img:domain+"/products/poisson-malangwa.jpg"
          ,qte:1
        },

      ]
    },
    {
      title:"VIVRIERS SEC",
      img:"",
      sousCategories: [
      ],
      produits:[
        {
          title:"Semoule de mais(25kg)",
          prix:20.8,
          devise:"$",
          img:domain+"/products/semoule-25kg.jpg"
        },
        {
          title:"Sucre(5kg, Kwilu ngongo)",
          prix:6.5,
          devise:"$",
          img:domain+"/products/sucre.jpg"
        },
        {
          title:"Sucre blanc(5kg)",
          prix:6.5,
          devise:"$",
          img:domain+"/products/sucre-blanc.jpg"
        },

      ]
    },
    {
      title:"CONSERVES",
      img:"",
      sousCategories: [
      ],
      produits: [
        {
          title:"Corned beef(400g)",
          prix:1.46,
          devise:"$",
          img:domain+"/products/corned-beef.jpg"
        },
        {
          title:"Pilchard(425g)",
          prix:1.3,
          devise:"$",
          img:domain+"/products/pilchard.jpg"
        },
        {
          title:"Sardine",
          prix:0.75,
          devise:"$",
          img:domain+"/products/sardine.jpg"
        }
      ]
    },
    {
      title:"HUILES ET GRAISSES VÉGÉTALES",
      img:"",
      sousCategories: [
      ]
    },
    {
      title:"SAVONNERIE ET HYGIENE DU CORPS",
      img:"",
      sousCategories: [
      ]
    },
    {
      title:"NETTOYAGE ET ENTRETIEN MÉNAGER",
      img:"",
      sousCategories: [
      ]
    },
    {
      title:"ÉLECTROMÉNAGERS",
      img:"",
      sousCategories: [
      ]
    },
    {
      title:"AUTRES",
      img:"",
      sousCategories: [
      ]
    },
  ],
  panier:[],
  users:[],
  user:{},
  productsPack:[
    {
      title:"Pack 50$",
      prix:50,
      devise:"$",
      img:domain+"/products/box2.png",
      products:[
          "10 kg de riz",
          "10 kg de farine de maïs",
          "2 kg de sucre",
          "1 boîte de lait 400g",
          "1 boîte de BLUE BAND 450 g",
          "1 paquet d'épices",
          "5 boîtes de sardine",
          "2 litres d'huile SIMBA",
          "5 savons Le COQ",
          "5 savons MONGANGA"

      ]
    },
    {
      title:"Pack 100$",
      prix:100,
      devise:"$",
      img:domain+"/products/box2.png",
      products:[
        "1 sac de riz 25 kg",
        "1 sac de farine de semoule 25 kg",
        "1 sachet de sucre 5 kg",
        "1 boîte de lait 400 g",
        "1 boîte de BLUE BAND 450 g",
        "1 Kg de poissons salés",
        "1 paquet d'épices",
        "3 litres d'huile SIMBA",
        "2 boîtes de Corned beef 400 g",
        "2 boîtes de Pilchard 425 g",
        "2 boîtes de sardine",
        "5 savons LE COQ",
        "5 savons MONGANGA"
      ]
    },
    {
      title:"Pack 150$",
      prix:150,
      devise:"$",
      img:domain+"/products/box2.png",
      products:[
        "1 sac de riz 25 kg",
        "1 sac de farine de mais 25 kg",
        "1 sachet de sucre 5 kg",
        "1 boîte de lait 900 g",
        "1 boîte de BLUE BAND 900 g",
        "1 Kg de poissons salés",
        "1 paquet d'épices",
        "5 litres d'huile SIMBA",
        "10 boîtes de Corned beef 400 g",
        "10 boîtes de Pilchard 425 g",
        "10 boîtes de sardine",
        "20 savons LE COQ",
        "20 savons MONGANGA"
      ]
    },
    {
      title:"Pack 200$",
      prix:200,
      devise:"$",
      img:domain+"/products/box2.png",
      products:[
        "1 sac de riz 50 kg",
        "1 sac de farine de mais 25 kg",
        "1 sachet de sucre 5 kg",
        "1 boîte de lait 900 g",
        "1 boîte de BLUE BAND 900 g",
        "3 Kg de poissons salés",
        "1 paquet d'épices",
        "10 litres d'huile SIMBA",
        "20 boîtes de Corned beef 400 g",
        "20 boîtes de Pilchard 425 g",
        "20 boîtes de sardine",
        "1 carton savons LE COQ",
        "1 carton savons MONGANGA"
      ]
    }
  ]
};

export default states;
